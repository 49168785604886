// ** Icons Import
import { FileText, Circle, Square, Info } from "react-feather"

export default [
  {
    id: "contact",
    title: "Contact",
    icon: <Info size={20} />,
    children: [
      {
        id: "faq",
        title: "FAQ",
        icon: <Circle size={12} />,
        navLink: "/dashboard/contact/faq"
      }
      // {
      //   id: 'pricing',
      //   title: 'Pricing',
      //   icon: <Circle size={12} />,
      //   navLink: '/dashboard/contact/pricing'
      // }
    ]
  }
]
