// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
// import axios from 'axios'
import axios from '@configs/axios'
import { getUserData } from '@utils'

export const getSubscriptionPlan = createAsyncThunk('user/subscription', async () => {
  const user = getUserData()
  const response = await axios.get('/check-subscription', {
    headers: {
        Authorization: `Bearer ${user.accessToken}`
    }
  })
  return response.data
})

export const userSubscription = createSlice({
  name: 'userSubscription',
  initialState: {
    status: 'No subscription plan',
    statusCode: null,
    endDate: null,
    startDate: null,
    data: null,
    ongoing: 0
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getSubscriptionPlan.fulfilled, (state, action) => {
        state.status = action.payload.status
        state.statusCode = action.payload.statusCode
        state.endDate = action.payload.endDate
        state.startDate = action.payload.startDate
        state.data = action.payload.data
        state.ongoing = action.payload.ongoing
      })
  }
})

export default userSubscription.reducer
