// ** Icons Import
import { Database } from 'react-feather'

export default [
    {
        id: 'startup',
        title: 'Startup',
        icon: <Database />,
        navLink: '/dashboard/startup'
    }
]
