// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
// import axios from 'axios'
import axios from '@configs/axios'
import { getUserData } from '@utils'

export const getCaptable = createAsyncThunk('captable', async (id) => {
  const user = getUserData()
  const response = await axios.get(`/captable/${id}`, {
    headers: {
        Authorization: `Bearer ${user.accessToken}`
    }
  })
  return response.data.data
})

export const captable = createSlice({
  name: 'captable',
  initialState: {
    data: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getCaptable.fulfilled, (state, action) => {
        state.data = action.payload
      })
  }
})

export default captable.reducer
