// ** Icons Import
import { Activity } from 'react-feather'

export default [
    {
        id: 'database',
        title: 'Database',
        icon: <Activity />,
        navLink: '/dashboard/analytics'
    }
]
