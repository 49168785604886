// ** Icons Import
import { Home } from 'react-feather'

export default [
  {
    id: 'dashboard',
    title: 'Dashboard',
    icon: <Home size={12} />,
    navLink: '/dashboard/index'
  }
]
