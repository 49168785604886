import { lazy } from 'react'

const DashboardAnalytics = lazy(() => import('../../views/startupindonesia/dashboard'))

const DashboardRoutes = [
  {
    path: '/dashboard/index',
    element: <DashboardAnalytics />
  }
]

export default DashboardRoutes
