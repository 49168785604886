// ** React Imports
import { lazy } from 'react'
// import { Navigate } from 'react-router-dom'
const InvoicePreview = lazy(() => import('../../views/startupindonesia/invoice'))
const InvoicePrint = lazy(() => import('../../views/startupindonesia/invoice/Print'))

const InvoiceRoutes = [
    {
        element: <InvoicePreview />,
        path: '/dashboard/invoice/preview/:id'
    },
    {
        element: <InvoicePrint />,
        path: '/dashboard/invoice/print/:id',
        meta: {
            layout: 'blank'
        }
    }
]

export default InvoiceRoutes