// ** React Imports
import { lazy } from "react"

const LandingPage = lazy(() => import("../../views/startupindonesia/landing-page"))
const Pricing = lazy(() => import("../../views/startupindonesia/landing-page/Pricing"))
const PricingStartup = lazy(() => import("../../views/startupindonesia/landing-page/PricingStartup"))
const Onboarding = lazy(() => import("../../views/startupindonesia/landing-page/Onboarding"))

const LandingPageRoutes = [
  {
    element: <LandingPage />,
    path: "/",
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: false
    }
  },
  {
    element: <Pricing />,
    path: "/pricing",
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: false
    }
  },
  {
    element: <PricingStartup />,
    path: "/pricing/startup",
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: false
    }
  },
  {
    element: <Onboarding />,
    path: "/onboarding",
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: false
    }
  }
]

export default LandingPageRoutes
