// ** Navigation imports
// import apps from './apps'
// import pages from './pages'
// import forms from './forms'
// import tables from './tables'
// import others from './others'
// import charts from './charts'
// import dashboards from './dashboards'
// import uiElements from './ui-elements'

import dashboard from "./dashboard"
import database from "./database"
import contact from "./contact"
import article from "./article"
import financial from "./financial"
// import account from './account'

// ** Merge & Export
export default [...dashboard, ...database]
