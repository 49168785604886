// ** React Imports
import { Fragment, useEffect } from 'react'

// ** Custom Components
import NavbarUser from './NavbarUser'
import NavbarBookmarks from './NavbarBookmarks'
import { useDispatch, useSelector } from 'react-redux'
import { getNotification } from '../../../../redux/store/notification'

const ThemeNavbar = props => {
  // ** Props
  const { skin, setSkin, setMenuVisibility } = props
  const dispatch = useDispatch()
  const notification = useSelector(state => state.userNotification)
  useEffect(() => {
    dispatch(getNotification())
  }, [])
  const user = JSON.parse(localStorage.getItem('userData'))

  return (
    <Fragment>
      <div className='bookmark-wrapper d-flex align-items-center'>
        <NavbarBookmarks setMenuVisibility={setMenuVisibility} user={user} />
      </div>
      <NavbarUser skin={skin} setSkin={setSkin} notification={notification} />
    </Fragment>
  )
}

export default ThemeNavbar
