// ** React Imports
import { lazy } from 'react'
// import { Navigate } from 'react-router-dom'
const Pricing = lazy(() => import('../../views/startupindonesia/pricing'))

const ContactRoutes = [
  {
    element: <Pricing />,
    path: '/dashboard/contact/pricing'
  }
]

export default ContactRoutes
