// ** React Imports
import { lazy } from 'react'
// import { Navigate } from 'react-router-dom'
const BlogList = lazy(() => import('../../views/startupindonesia/article/list'))
const BlogDetails = lazy(() => import('../../views/startupindonesia/article/details'))
// const BlogEdit = lazy(() => import('../../views/startupindonesia/articleblog/edit'))

const ArticleRoutes = [
  {
    element: <BlogList />,
    path: '/dashboard/article'
  },
  {
    element: <BlogDetails />,
    path: '/dashboard/article/:slug'
  }
]

export default ArticleRoutes
