// ** Icons Import
import { CreditCard } from 'react-feather'

export default [
  {
    id: 'financial',
    title: 'Financial Data',
    icon: <CreditCard size={12} />,
    navLink: '/dashboard/financial'
  }
]
