// ** Icons Import
import { FileText } from 'react-feather'

export default [
  {
    id: 'article',
    title: 'Articles',
    icon: <FileText size={12} />,
    navLink: '/dashboard/article'
  }
]
