// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
// import axios from 'axios'
import axios from '@configs/axios'
import { getUserData } from '@utils'

export const getUserDetail = createAsyncThunk('user/detail', async () => {
  const user = getUserData()
  const response = await axios.get('/detail', {
    headers: {
        Authorization: `Bearer ${user.accessToken}`
    }
  })
  return response.data.data
})

export const getStartupDetail = createAsyncThunk('user/startup/detail', async () => {
  const user = getUserData()
  const response = await axios.get('/startup', {
    headers: {
        Authorization: `Bearer ${user.accessToken}`
    }
  })
  return response.data.data
})

export const userDetail = createSlice({
  name: 'userDetail',
  initialState: {
    data: {},
    startup: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getUserDetail.fulfilled, (state, action) => {
        state.data = action.payload
      })
      .addCase(getStartupDetail.fulfilled, (state, action) => {
        state.startup = action.payload
      })
  }
})

export default userDetail.reducer
