// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
// import axios from 'axios'
import axios from "@configs/axios"
import { getUserData } from "@utils"

// export const getDatabase = createAsyncThunk('database', async (id) => {
export const getDatabase = createAsyncThunk("database", async () => {
  const user = getUserData()
  // const response = await axios.get(`/database/${id}`, {
  const response = await axios.get(`/database/${user.id}`, {
    headers: {
      Authorization: `Bearer ${user.accessToken}`
    }
  })
  return response.data.data
})

export const database = createSlice({
  name: "database",
  initialState: {
    data: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDatabase.fulfilled, (state, action) => {
      state.data = action.payload
    })
  }
})

export default database.reducer
