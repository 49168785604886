// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
// import axios from 'axios'
import axios from '@configs/axios'
import { getUserData } from '@utils'

export const getPlan = createAsyncThunk('user/plan', async () => {
  const user = getUserData()
  const response = await axios.get('/plan')
  return response.data.data
})

export const userPlan = createSlice({
  name: 'userPlan',
  initialState: {
    data: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getPlan.fulfilled, (state, action) => {
        state.data = action.payload
      })
  }
})

export default userPlan.reducer
