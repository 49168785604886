// ** React Imports
import { Outlet } from 'react-router-dom'

// ** Core Layout Import
// !Do not remove the Layout import
import Layout from '@layouts/VerticalLayout'

// ** Menu Items Array
import navVC from '@src/navigation/vertical'
import navStartup from '@src/navigation/vertical/startup'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getUserDetail } from '@store/store/user'

const VerticalLayout = props => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.userDetail)

  useEffect(() => {
    dispatch(getUserDetail())
  }, [])
  // const [menuData, setMenuData] = useState([])

  // ** For ServerSide navigation
  // useEffect(() => {
  //   axios.get(URL).then(response => setMenuData(response.data))
  // }, [])

  return (
    <Layout menuData={user?.data?.isStartup ? navStartup : navVC} {...props}>
      <Outlet />
    </Layout>
  )
}

export default VerticalLayout
