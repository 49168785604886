import { lazy } from 'react'

const Financial = lazy(() => import('../../views/startupindonesia/financial'))

const FinancialRoutes = [
  {
    path: '/dashboard/financial',
    element: <Financial />
  }
]

export default FinancialRoutes
