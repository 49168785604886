// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
// import axios from 'axios'
import axios from '@configs/axios'
import { getUserData } from '@utils'

export const getTransaction = createAsyncThunk('user/transaction', async () => {
  const user = getUserData()
  const response = await axios.get('/transaction', {
    headers: {
        Authorization: `Bearer ${user.accessToken}`
    }
  })
  return response.data.data
})

export const getTransactionDetail = createAsyncThunk('user/transaction/detail', async (id) => {
    const user = getUserData()
    const response = await axios.get(`/transaction/${id}`, {
      headers: {
          Authorization: `Bearer ${user.accessToken}`
      }
    })
    return response.data.data
  })

export const userTransaction = createSlice({
  name: 'userTransaction',
  initialState: {
    data: [],
    detail: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getTransaction.fulfilled, (state, action) => {
        state.data = action.payload
      })
      .addCase(getTransactionDetail.fulfilled, (state, action) => {
        state.detail = action.payload
      })
  }
})

export default userTransaction.reducer
