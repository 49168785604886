// ** React Imports
import { lazy } from 'react'
// import { Navigate } from 'react-router-dom'
const StartupForm = lazy(() => import('../../views/startupindonesia/startup/form'))
const StartupFormUpdate = lazy(() => import('../../views/startupindonesia/startup/form/update'))
const Startup = lazy(() => import('../../views/startupindonesia/startup'))
// const BlogEdit = lazy(() => import('../../views/startupindonesia/articleblog/edit'))

const StartupRoutes = [
  {
    element: <Startup />,
    path: '/dashboard/startup'
  },
  {
    element: <StartupForm />,
    path: '/dashboard/startup/form'
  },
  {
    element: <StartupFormUpdate />,
    path: '/dashboard/startup/form/update'
  }
]

export default StartupRoutes
