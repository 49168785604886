import axios from "axios"
export const customAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

customAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("user")
      window.location.href = "/"
    }
    return Promise.reject(error)
  }
)

export default customAxios
