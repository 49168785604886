import { lazy } from 'react'

const Database = lazy(() => import('../../views/startupindonesia/database'))

const DatabaseRoutes = [
  {
    path: '/dashboard/database',
    element: <Database />
  }
]

export default DatabaseRoutes
