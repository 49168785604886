// ** React Imports
import { lazy } from 'react'
// import { Navigate } from 'react-router-dom'
const FAQ = lazy(() => import('../../views/startupindonesia/faq'))

const FAQRoutes = [
  {
    element: <FAQ />,
    path: '/dashboard/contact/faq'
  }
]

export default FAQRoutes
