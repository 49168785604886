// ** React Imports
import { lazy } from 'react'
// import { Navigate } from 'react-router-dom'
const Account = lazy(() => import('../../views/startupindonesia/account-settings'))

const AccountRoutes = [
  {
    element: <Account />,
    path: '/dashboard/account'
  }
]

export default AccountRoutes
