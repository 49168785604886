// ** React Imports
import { lazy } from 'react'

const Login = lazy(() => import('../../views/startupindonesia/authentication/Login'))
const Register = lazy(() => import('../../views/startupindonesia/authentication/Register'))
const RegisterStartup = lazy(() => import('../../views/startupindonesia/authentication/startupindonesia/Register'))
const EmailVerify = lazy(() => import('../../views/startupindonesia/authentication/EmailVerify'))
const EmailVerified = lazy(() => import('../../views/startupindonesia/authentication/EmailVerified'))
const ForgotPassword = lazy(() => import('../../views/startupindonesia/authentication/ForgotPassword'))
const ResetPassword = lazy(() => import('../../views/startupindonesia/authentication/ResetPassword'))

const AuthRoutes = [
  {
    path: '/login',
    element: <Login />,
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: '/register',
    element: <Register />,
    meta: {
      layout: 'blank',
      publicRoute: true
    }
  },
  {
    path: '/register/startup',
    element: <RegisterStartup />,
    meta: {
      layout: 'blank',
      publicRoute: true
    }
  },
  {
    path: '/verify-email',
    element: <EmailVerify />,
    meta: {
      layout: 'blank',
      publicRoute: true
    }
  },
  {
    path: '/verified-email/:id',
    element: <EmailVerified />,
    meta: {
      layout: 'blank',
      publicRoute: true
    }
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />,
    meta: {
      layout: 'blank',
      publicRoute: true
    }
  },
  {
    path: '/reset-password/:id',
    element: <ResetPassword />,
    meta: {
      layout: 'blank',
      publicRoute: true
    }
  }
]

export default AuthRoutes
