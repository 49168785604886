// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
// import axios from 'axios'
import axios from '@configs/axios'
import { getUserData } from '@utils'

export const getArticle = createAsyncThunk('article', async () => {
  const user = getUserData()
  const response = await axios.get(`/article`, {
    headers: {
        Authorization: `Bearer ${user.accessToken}`
    }
  })
  return response.data
})

export const article = createSlice({
  name: 'article',
  initialState: {
    data: [],
    totalRows: 0
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getArticle.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.totalRows = action.payload.totalRows
      })
  }
})

export default article.reducer
