// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
// import axios from 'axios'
import axios from '@configs/axios'
import { getUserData } from '@utils'

export const getNotification = createAsyncThunk('user/notification', async () => {
  const user = getUserData()
  const response = await axios.get('/notification', {
    headers: {
        Authorization: `Bearer ${user.accessToken}`
    }
  })
  return response.data.data
})

export const readNotification = createAsyncThunk('user/notification/read', async (id, { dispatch }) => {
    const user = getUserData()
    const response = await axios.get(`/notification/read/${id}`, {
      headers: {
          Authorization: `Bearer ${user.accessToken}`
      }
    })
    await dispatch(getNotification())
    return response.data
  })

export const userNotification = createSlice({
  name: 'userNotification',
  initialState: {
    data: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getNotification.fulfilled, (state, action) => {
        state.data = action.payload
      })
  }
})

export default userNotification.reducer
