// ** Icons Import
import { Database } from 'react-feather'

export default [
    {
        id: 'database',
        title: 'Database',
        icon: <Database />,
        navLink: '/dashboard/database'
    }
]
